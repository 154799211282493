<template>
  <div class="Twofa__Container">
    <Parallax :images="ParallaxImages()" />
    <div class="Twofa">
      <div class="Twofa_Blur" />
      <div class="title"><p>Two steps Verification</p></div>
      <div class="subtitle"><p>Enter your code</p></div>
      <div class="field">
        <LoginField
          ref="textFieldRef"
          :input-type="'text'"
          @textChanged="update2FA($event)"
        />
      </div>
      <CTA
        class="Twofa__Button"
        label="Send"
        @clickButton="Send"
        :color="'#d7321e'"
      />
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import { EventBus } from '@/event-bus.js'
import CTA from '@/components/button/CTA'
import LoginField from '../login/components/LoginField'
import Parallax from '@/components/parallax/Parallax.vue'

export default {
  data () {
    return {
      twofaVal: null
    }
  },
  components: {
    CTA,
    LoginField,
    Parallax
  },
  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    update2FA (value) {
      this.twofaVal = value
    },
    async Send () {
      try {
        await api.auth.verify2Fa(this.twofaVal)
        // await this.$store.dispatch('SetTwofa', ans.data.data.token)
        await this.$store.dispatch('StoreTokensLocally')
        this.$router.push({ name: 'selection' })
      } catch (e) {
        EventBus.$emit('notify', {
          type: 'error',
          message: 'Wrong 2FA code',
          hidden: true
        })
        this.twofaVal = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/main.scss';

.Twofa__Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.Twofa__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Input_elt {
  background: rgba(255, 255, 255, 0.5);
}

.Twofa {
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  height: 310px;
  width: 365px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Twofa_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.FormElement {
  margin: auto;
  margin-top: 0.7em;
  flex-direction: row;
  justify-content: center;
}

.title {
  p {
    font-family: 'Gotham Bold';
    @include fluid-type($min_width, $max_width, $min_font * 0.8, $max_font * 1);
    text-transform: uppercase;
    text-align: center;
  }
}

.subtitle {
  p {
    font-family: 'Gotham Book';
    @include fluid-type($min_width, $max_width, $min_font * 0.8, $max_font * 1);
    text-align: center;
  }
}

.Twofa__Button {
  height: 20%;
  width: 50%;
  margin: 0 auto;
}

.field {
  height: 20%;
}
</style>
